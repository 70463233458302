<div class="item-simple item-shadow">
  <div class="d-flex align-items-center">
    @if (item?.image) {
    <div class="product-img">
      <img
        [src]="item.image | pathImage : 'admin'"
        [alt]="item.image | altImage"
      />
    </div>
    }
    <div
      class="d-flex align-items-center justify-content-between"
      style="flex: 1"
    >
      <div>
        <h6>{{ item.title }}</h6>
      </div>
      <h6>{{ item?.price?.price | currency : 'USD' }}</h6>
    </div>
  </div>
  <div class="mb-3"></div>

  <h5 class="mb-2">Order summary</h5>
  <ng-content select="[quantity]"></ng-content>

  <div class="d-flex justify-content-between mb-2">
    <p class="lighter">Subtotal:</p>
    <h5>{{ subTotal || item?.price?.price | currency : 'USD' }}</h5>
  </div>
  @if (discount) {
  <div class="d-flex justify-content-between mb-2">
    <p class="lighter">Discount:</p>
    <h5>{{ discount | currency : 'USD' }}</h5>
  </div>
  }
  <div class="divider"></div>
  <div class="mb-2"></div>
  <div class="d-flex justify-content-between mb-3">
    <p class="lighter">{{ totalTitle }}</p>
    <h4>{{ total || item?.price?.price | currency : 'USD' }}</h4>
  </div>
  <ng-content select="[subscriptionText]"></ng-content>
</div>
