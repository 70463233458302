function plans(twelve_week_price: number, twenty_four_week_price: number): any {
  return [
    {
      id: 1,
      quantity: 1,
      subscription: true,
      title: 'Monthly Plan *',
      price_title: 'monthly',
      payment_option: [],
      benefits: [
        {
          text: 'Medication shipped within 72 hrs'
        },
        {
          text: 'Physician consult with 24 hrs'
        },
        {
          text: 'Unlimited messaging with provider'
        },
        {
          text: '4 weeks of medication'
        },
        {
          text: 'Free shipping'
        },
        {
          text: 'Cancel Anytime'
        }
      ]
    },
    {
      id: 2,
      quantity: 3,
      subscription: false,
      title: '12-Week Plan',
      price_title: 'month for 18 month',
      payment_option: [
        {
          id: 1,
          title: 'Pay Over Time with Monthly Financing',
          surcharge: true,
          prices: [
            {
              id: 1,
              price_title: `From <b>$${twelve_week_price}</b>/month for 18 months **`,
              price: twelve_week_price,
              title: 'month for 18 month'
            },
            {
              id: 2,
              price_title: `From <b>$${twelve_week_price}</b>/month for 36 months **`,
              price: twelve_week_price,
              title: 'month for 36 month'
            }
          ]
        },
        {
          id: 2,
          surcharge: false,
          title: 'Pay in full Now'
        }
      ],
      benefits: [
        {
          text: 'Medication shipped within 72 hrs'
        },
        {
          text: 'Physician consult with 24 hrs'
        },
        {
          text: 'Unlimited messaging with provider'
        },
        {
          text: '12 weeks of medication'
        },
        {
          text: 'Free shipping'
        }
      ]
    },
    {
      id: 3,
      quantity: 6,
      subscription: false,
      title: '24-Week Plan',
      payment_option: [
        {
          id: 1,
          title: 'Pay Over Time with Monthly Financing',
          surcharge: true,
          prices: [
            {
              id: 1,
              price_title: `From <b>$${twenty_four_week_price}</b>/month for 36 months ***`,
              price: twenty_four_week_price,
              title: 'month for 36 month'
            },
            {
              id: 2,
              price_title: `From <b>$${twenty_four_week_price}</b>/month for 36 months ***`,
              price: twenty_four_week_price,
              title: 'month for 36 month'
            }
          ]
        },
        {
          id: 2,
          surcharge: false,
          title: 'Pay in full Now'
        }
      ],
      benefits: [
        {
          text: 'Medication shipped within 72 hrs'
        },
        {
          text: 'Physician consult with 24 hrs'
        },
        {
          text: 'Unlimited messaging with provider'
        },
        {
          text: '24 weeks of medication'
        },
        {
          text: 'Free shipping'
        }
      ]
    }
  ];
}

export function planTypes(price: any): any {
  return {
    1: {
      title: 'Compounded Semaglutide Injections',
      plans: plans(price.twelve_week_price, price.twenty_four_week_price),
      disclamers: [
        {
          text: '* The Monthly Plan is offered on a subscription basis. Your payment method will be automatically charged every 4 weeks as described during the checkout process. You may cancel vour subscrintion to prevent future renewals at an time before the cancellation cutoff date as indicated to vou at the time vou purchase this subscription service. You can cancel by going to settings in your account and selecting cancel my subscription or by calling customer service at <a href="tel:+18882487929" class="link-hover text-underline" target="_blank">(888) 24-URWAY.</a>'
        },
        {
          text: '** Rates from 10-36% APR. For example, a $897 purchase might cost $55.96/mo over 18 months at 15% APR. Payment options through Affirm are subject to an eligibility check and are provided by these lending partners: <a href="https://affirm.com/lenders" class="link-hover text-underline" target="_blank">affirm.com/lenders</a>. Options depend on your purchase amount, and a down payment may be required. See <a href="https://affirm.com/licenses" class="link-hover text-underline" target="_blank">affirm.com/licenses</a> for important into on state licenses and notifications'
        },
        {
          text: '*** Rates from 10-36% APR. For example, a $1,794 purchase might cost $62.19/mo over 36 months at 15% APR. Payment options through Affirm are subject to an eligibility check and are provided by these lending partners: <a href="https://affirm.com/lenders" class="link-hover text-underline" target="_blank">affirm.com/lenders</a>. Options depend on vour burchase amount. and a down davment mav be reauired. See <a href="https://affirm.com/licenses" class="link-hover text-underline" target="_blank">affirm.com/licenses</a> for important info on state licenses and notifications.'
        }
      ]
    },
    2: {
      title: 'Compounded Tirzepatide Injections',
      plans: plans(price.twelve_week_price, price.twenty_four_week_price),
      disclamers: [
        {
          text: '* The Monthly Plan is offered on a subscription basis. Your payment method will be automatically charged every 4 weeks as described during the checkout process. You may cancel your subscription to prevent future renewals at any time before the cancellation cutoff date as indicated to you at the time you purchase this subscription service. You can cancel by going to settings in your account and selecting cancel my subscription or by calling customer service at <a href="tel:+18882487929" class="link-hover text-underline" target="_blank">(888) 24-URWAY.</a>'
        },
        {
          text: '** Rates from 10-36% APR. For example, a $1,347 purchase might cost $84.03/mo over 18 months at 15% APR. Payment options through Affirm are subject to an eligibility check and are provided by these lending partners: <a href="https://affirm.com/lenders" class="link-hover text-underline" target="_blank">affirm.com/lenders</a>. Options depend on your purchase amount, and a down payment may be required. See <a href="https://affirm.com/licenses" class="link-hover text-underline" target="_blank">affirm.com/licenses</a> for important info on state licenses and notifications.'
        },
        {
          text: '*** Rates from 10-36% APR. For example, a $2,694 purchase might cost $93.39/mo over 36 months at 15% APR. Payment options through Affirm are subject to an eligibility check and are provided by these lending partners: <a href="https://affirm.com/lenders" class="link-hover text-underline" target="_blank">affirm.com/lenders</a>. Options depend on your purchase amount, and a down payment may be required. See <a href="https://affirm.com/licenses" class="link-hover text-underline" target="_blank">affirm.com/licenses</a> for important into on state licenses and notifications.'
        }
      ]
    }
  };
}
