import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PathImagePipe } from '../../../../shared/pipes/path-image.pipe';
import { CurrencyPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { AltImagePipe } from 'src/app/shared/pipes/alt-image.pipe';

@Component({
  selector: 'app-product-card',
  standalone: true,
  templateUrl: './product-card.component.html',
  imports: [
    RouterLink,
    PathImagePipe,
    CurrencyPipe,
    MatSelectModule,
    AltImagePipe
  ]
})
export class ProductCardComponent {
  @Input() item!: any;
  @Input() plan!: any;
  @Input() subTotal: number = 0;
  @Input() total: number = 0;
  @Input() tax: number = 0;
  @Input() discount: number = 0;
  @Input() totalTitle = 'Total';
}
