import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { promocodePattern } from '../../../../core/validators/patterns';

@Component({
  selector: 'app-order-promocode',
  standalone: true,
  templateUrl: './order-promocode.component.html',
  imports: [RouterLink, ReactiveFormsModule, MatFormFieldModule, MatInputModule]
})
export class OrderPromocodeComponent implements OnInit {
  @Input() disabled = false;
  @Input() code!: any;
  @Output() apply: EventEmitter<string> = new EventEmitter();
  @Output() remove: EventEmitter<boolean> = new EventEmitter();

  promoCodeApplied = false;
  promoForm = new FormGroup({
    code: new FormControl(null, [
      Validators.required,
      Validators.pattern(promocodePattern)
    ])
  });

  ngOnInit(): void {
    if (this.code) {
      this.promoForm.get('code')?.setValue(this.code);
      this.promoCodeApplied = true;
    }
  }

  removePromoCode() {
    this.remove.emit(true);
    this.promoCodeApplied = false;
    this.promoForm.reset();
  }

  applyPromoCode() {
    if (this.promoForm.valid) {
      this.apply.emit(this.promoForm.get('code')?.value || '');
      this.promoCodeApplied = true;
    }
  }
}
