<form [formGroup]="promoForm">
  <div class="row row-xs">
    <div class="col-8">
      <mat-form-field appearance="fill" class="app-form-field no-margin">
        <mat-label>Promocode</mat-label>
        <input matInput formControlName="code" [readonly]="promoCodeApplied" />
      </mat-form-field>
    </div>
    @if (promoCodeApplied) {
    <div class="col-4">
      <button
        [disabled]="disabled"
        (click)="removePromoCode()"
        class="app-button btn-lg btn-invalid btn-full"
      >
        Remove
      </button>
    </div>
    }@else{
    <div class="col-4">
      <button
        (click)="applyPromoCode()"
        [disabled]="!promoForm.valid || disabled"
        class="app-button btn-lg btn-primary btn-full"
      >
        Apply
      </button>
    </div>
    }
  </div>
</form>
