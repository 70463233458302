<div class="text-center">
  <div class="bs-xs-25"></div>
  <h4>Questions?</h4>
  <div class="bs-xs-15"></div>
  <div class="d-flex align-items-center justify-content-center">
    <h6>Call:&nbsp;</h6>
    <a class="text-lg link-hover" href="tel:+18882487929">(888) 24-URWAY</a>
  </div>
  <div class="bs-xs-5"></div>
  <div class="d-flex align-items-center justify-content-center">
    <h6>Email:&nbsp;</h6>
    <a class="text-lg link-hover" href="mailto:support@urwayhealth.com">
      support&#64;urwayhealth.com
    </a>
  </div>
  <div class="bs-xs-5"></div>
  <p>
    Use of this platform is subject to
    <a
      [routerLink]="['/page/notice_of_privacy_practices']"
      class="text-underline link-hover"
      target="_blank"
    >
      Privacy Practices
    </a>
    and
    <a
      [routerLink]="['/page/privacy_policy']"
      class="text-underline link-hover"
      target="_blank"
    >
      Privacy Policy
    </a>
  </p>
  <div class="bs-xs-15"></div>
</div>
