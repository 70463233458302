import { DOCUMENT } from '@angular/common';
import {
  Component,
  DestroyRef,
  Inject,
  Input,
  OnInit,
  inject
} from '@angular/core';
declare var Stripe: any;

@Component({
  selector: 'app-stripe-form',
  standalone: true,
  imports: [],
  templateUrl: './stripe-form.component.html'
})
export class StripeFormComponent implements OnInit {
  @Input({ required: true }) public_key!: string;
  @Input({ required: true }) secret!: string;
  stripCheckoutForm: any;

  constructor(@Inject(DOCUMENT) private document: Document) {
    inject(DestroyRef).onDestroy(() => {
      if (this.stripCheckoutForm) {
        this.stripCheckoutForm.unmount();
        this.stripCheckoutForm.destroy();
        this.stripCheckoutForm = null;
        this.document.getElementById('stripe-script')!.remove();
      }
    });
  }

  ngOnInit(): void {
    this.loadStripeScript();
  }

  public loadStripeScript() {
    if (this.document.getElementById('stripe-script')) {
      this.initStripe();
    } else {
      let node = this.document.createElement('script');
      node.id = 'stripe-script';
      node.src = 'https://js.stripe.com/v3/';
      node.type = 'text/javascript';
      node.async = true;
      node.onload = () => {
        this.initStripe();
      };
      this.document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  async initStripe() {
    this.stripCheckoutForm = await Stripe(this.public_key).initEmbeddedCheckout(
      {
        clientSecret: this.secret
      }
    );
    this.stripCheckoutForm.mount('#checkout');
  }
}
