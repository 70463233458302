import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-step-contact-item',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './step-contact-item.component.html'
})
export class StepContactItemComponent {}
